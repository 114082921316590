import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/Layout';
import ColoredCategory from '../components/ColoredCategory';
import Colors from '../components/Colors';
import { FacebookProvider, Comments } from 'react-facebook';
import { Link } from 'gatsby';
import CategoryHelper from '../helpers/Category';

const prettyDate = date => {
  const monthMapper = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  };

  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);

  return `${monthMapper[month]} ${day}, ${year}`;
};

const renderTags = (tag, index) => {
  return (
    <Link
      to={`/topics/${tag.replace(/\s+/g, '-').toLowerCase()}`}
      key={index}
      css={css`
        background: #dedede;
        padding: 0.15em 15px;
        margin: 0 5px 0.25em 0;
        color: #484848;
        border-radius: 6px;
        font-size: 0.8rem;
        letter-spacing: 0.2rem;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          color: ${Colors.main};
        }
      `}
    >
      {tag}
    </Link>
  );
};
export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author
        category
        status
        date
        slug
        image {
          publicURL
        }
        excerpt
        private
        metaDescription
        previewTitle
        tags
        canonicalurl,
        ogpagetype
      }
      body
    }
  }
`;
const PostTemplate = ({ data: { mdx: post } }) => {
  // Facebook
  const commentURL = `https://www.christianbytes.com/${CategoryHelper.getCategoryLocation(
    post.frontmatter.category,
    post.frontmatter.slug
  )}`;

  const pageMetaDescription = post.frontmatter.metaDescription
    ? post.frontmatter.metaDescription
    : post.frontmatter.excerpt;

  return (
    <Layout
      jumbo={false}
      pageTitle={post.frontmatter.title}
      pageDescription={pageMetaDescription}
      image={post.frontmatter.image}
      canonicalurl={post.frontmatter.canonicalurl}
      ogpagetype={post.frontmatter.ogpagetype}
    >
      <div
        css={css`
          margin-right: 0.5rem;
        `}
      >
        {' '}
        <div className={'justify-content text-center font-weight-bold mb-2'}>
          <ColoredCategory category={post.frontmatter.category} />
        </div>
        <div>
          <div className={'justify-content text-center'}>
            <h1 className="post-title">{post.frontmatter.title}</h1>
            <p className={'mb-0 cb-secondary'}>
              Written by: {post.frontmatter.author}
            </p>
            <p className={'mb-4 cb-secondary'}>
              {prettyDate(post.frontmatter.date)}
            </p>
            <div
              css={css`
                display: -webkit-box;
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                margin-bottom: 1rem;
                padding: 0;
                justify-content: center;
              `}
            >
              {post.frontmatter.tags.map(function(tag, index) {
                return renderTags(tag, index);
              })}
            </div>
            <p
              css={css`
                border-top: 1px solid ${Colors.main};
                margin: 0 auto 2rem auto;
                width: calc(50%);
              `}
            ></p>
          </div>
          <div
            className="post-body"
            css={css`
              width: 96%;
              margin: 0 auto;
              @media (min-width: 700px) {
                width: 90%;
              }
              @media (min-width: 1100px) {
                width: 70%;
              }
            `}
          >
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </div>
        <div
          className="comments"
          css={css`
            width: 70%;
            margin: 0 auto;
          `}
        >
          <div>
            <h4
              css={css`
                margin: 2rem 0;
              `}
            >
              SHARE YOUR THOUGHTS!
            </h4>
            <FacebookProvider appId="560773418111611">
              <Comments href={commentURL} />
            </FacebookProvider>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PostTemplate;
